<template>
  <div class="content">
    <p class="page-header"></p>
    <loader v-if="!pageLoaded"/>
    <div v-else style="padding-bottom: 50px; margin-left: 70px">

      <div  class="Box3Container">
        <div class="Box3">
          <div class="Box3In">
            <p class="Box3Header" style="position:relative;display:block;height:auto;">Editörlerin Seçimi <span>Başvurusu</span></p>
            <div style="height: 132px; margin-top: 30px;">
              <div class="rainDropcontainer" style="margin-top: 5px;">
                <div class="firstLayer"></div>
                <div class="secondLayer"></div>
                <div class="thirdLayer"></div>
                <img style="margin-top: 18px; width: 91px; height: 91px;" :src="user.avatar_url" class="freelancerAvatar">
              </div>
            </div>

            <div v-if="1===2" class="profile-box-items">
              <div style="display: flex;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.portfolioCount > 4 ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                <p :class="user.seller.portfolioCount > 4 ? 'item-text-green' : 'item-text'">Portfolyo <span style="font-style: italic">(En az 5 içerik)</span></p>
                <p v-if="user.seller.portfolioCount < 4" class="item-text-link">Portfolyonu Oluştur</p>
              </div>
              <div style="display: flex;  margin-top: 16px;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.skillCount ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                <p :class="user.seller.skillCount ? 'item-text-green' : 'item-text'">Uzmanı Olduğun Alanlar & Araçlar</p>
                <p v-if="!user.seller.skillCount" class="item-text-link">Ekle</p>
              </div>
              <div style="display: flex;  margin-top: 16px;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.skillCount ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                <p :class="user.seller.skillCount ? 'item-text-green' : 'item-text'">Bionluk 101 Testi</p>
                <p v-if="!user.seller.skillCount" class="item-text-link">Testi Çöz</p>
              </div>
            </div>
            <router-link
              v-if="1===2"
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
              @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
              to="/settings" class="Box3Button">Profilini Güncelle</router-link>

            <div class="help-text">
              Bazı kategori sayfalarında bulunan Editörlerin Seçimi bölümünde yer almak istiyorsan bu alandan başvuru yapabilirsin. Başvurular <span>her ayın 20'si ile 27'si arasında</span> alınır. Takip eden ayın ilk gününde sonuçlar açıklanır.
            </div>

            <custom-button :button-type="'border'" :button-style="'width:200px; height:45px; padding-bottom:2px'"  disabled>Başvuru Yap</custom-button>
            <p style="font-size: 12px; margin-top: 10px;">Başvurular 20 Şubat tarihinde açılacak</p>

          </div>


        </div>
      </div>


    </div>

  </div>

</template>

<script>

import CustomButton from "@/pages/components/CustomButton";


  export default {
    components:{CustomButton},
    name: "src-pages-body-workstation-basvuru-v1",
    data() {
      return {

        pageLoaded: false,
      }
    },

   methods:{

   },

    created() {

      this.pageLoaded = true;
    }
  }
</script>

<style scoped lang="scss">

.help-text{
  font-size: 16px;
  margin: 30px auto;
  width: 550px;

  line-height: 1.5;
  text-align: center;
  color: #8b95a1;
  span{
    color: #2D3640;
    font-weight: 500;
  }
}
.Box3Container{

  display: flex;
  width: 100%;

  justify-content: space-between;
  height: 520px;
  .Box3{
    width: 770px;
    height: 480px;
  }
  .Box3In{

    display: flex;
    align-items: center;
    flex-direction: column;
    width: 770px;
    height: 460px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    transition: .2s;


    .Box3Button{

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      width: 180px;
      height: 42px;
      padding-bottom: 2px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);

      margin-top: 20px;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
      transition: .2s;
      &:hover{
        background-color: #fd4056!important;
        color:#fff
      }
      a{
        text-decoration: none;
        &:hover{
          color:#fff
        }
      }
    }




    .Box3Header{

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 52px;
      width: 390px;
      margin-top: 25px;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;

      span{
        font-weight: bold;
      }
    }

    img{
      margin-top: 30px;
      height: 120px;
      width: auto;
    }

    .Box3Desc{

      height: 96px;
      width: 240px;
      margin-top: 24px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #8b95a1;
      transition: .2s;
    }


  }
}

.profile-box-items{

  margin-top: 15px;
  margin-bottom: 5px;
  width: 630px;
  svg{
    width: 20px!important;
    height: 20px!important;
  }


  .profile-box-item-ok /deep/ {
    cursor: pointer;
    width: 16px;
    height: 16px;
    path {
      fill: #bfc8d2;
    }
  }

  .profile-box-item-ok-green /deep/ {
    cursor: pointer;
    width: 16px;
    height: 16px;
    path {
      fill: #26ca9a;
    }

  }

  .item-text{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #bfc8d2;
    margin-left: 12px;
    line-height: 1.33;
  }

  .item-text-green{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    color: #26ca9a;
    margin-left: 12px;
    line-height: 1.33;
  }

  .item-text-link{
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding-top: 3px;

    letter-spacing: normal;
    color: #f75466;
    margin-left: 12px;
    line-height: 1.33;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

.freelancerAvatar {
  width: 91px;
  height: 91px;
  border-radius: 100%;
  border: 2px solid #eaeaea;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 4;
}

.rainDropcontainer {
  position: relative;
  display: block;
  width: 132px;
  height: 132px;
}


.firstLayer {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  opacity: 0.1;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 3;
}

.secondLayer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  opacity: 0.08;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
}

.thirdLayer {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  opacity: 0.05;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}
</style>
